<template>
	<div>
		<v-toolbar dense class="rounded">
			<v-btn icon class="hidden-xs-only" @click="$router.push('/gametype')">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>

			<v-toolbar-title>POLITICAS DE DEPOSITOS Y RETIROS</v-toolbar-title>

			<v-spacer></v-spacer>
		</v-toolbar>

		<div class="mt-3 rounded white pa-3 text-justify">
			<p>
    <strong><u>Recargas:</u></strong>
</p>
<p>
    Las recargas serán acreditas al saldo del cliente de forma INMEDIATA, una
    vez se verifique la transacción y el usuario haya generado la solicitud de
    depósito dentro de la plataforma. Para recargar su cuenta el usuario
    contara con las opciones indicadas por trackandraces, dependiendo de la
    cartera en la cual se desee recargar.
</p>
<p>
    <strong><u>Retiros:</u></strong>
</p>
<p>
    Los Retiros deben ser solicitados por el cliente dentro de la plataforma,
    indicando correctamente la información solicitada. Referente a esto es
    importante:
</p>
<p>
    A) El valor máximo a retirar no puede ser mayor al saldo disponible en
    cuenta.
</p>
<p>
    B) El usuario podrá eliminar una nota de retiro que haya sido generada si
    así lo decide.
</p>
<p>
    C) Los retiros serán hechos efectivos en orden, dependiendo el número de
    solicitudes, una vez el usuario haya solicitado la nota de retiro,
    especificando toda la información necesaria y que el departamento de pago
    valide la información realizara el proceso de pago.
</p>
<p>
    <strong>Horario de depósitos y retiros:</strong>
</p>
<p>
    Se establece horario de depósitos y retiros de lunes a domingo de 9 am a 10
    pm de cada país.
</p>
<p>
    Se podrá realizar un máximo de un (01) retiro al día. Por cuenta.
</p>
<p>
    Todos los depósitos se deberán hacer por los medios regulares establecidos
por la página    <a href="http://www.trackandraces.com/">www.trackandraces.com</a>.
</p>
<p>
    Trackandraces, se compromete con los usuarios a responder de forma
    rápida a todas las solicitudes de recarga y retiros, una vez se haya
    validado la información del cliente. Nuestra prioridad es agilizar los
    procesos para mejorar la experiencia en la plataforma.
</p>
<p>
    <strong>Pagos en Dolares:</strong>
</p>
<p>Se le solicitará a los clientes los documentos necesarios para avalar el pago.</p>
<p>Monto mínimo a retirar 10$.</p>
<p>Se recibiran solicitudes de pago hasta las 7:00 pm hora de Estados Unidos.</p>
<p>Para montos menores a 250 Dólares, se realizarán por medio de Zelle (En un lapso no mayor a 24 horas).</p>
<p>Para montos superiores a 250 Dólares, el pago se realizará por medio de cheques electrónicos (ACH) o transferencia. Se procesa antes de las 7:00 pm y se hará efectivo al dia siguiente habil, las solicitudes realizadas luego de esa hora entraran en el proceso de las siguientes 24 horas.</p>
<p>Para montos superiores a 250 Dólares, solicitados viernes antes de las 7:00 pm. sábados y domingos el pago se hará efectivo en tu cuenta los días Lunes. ( Los días feriados no cuentan como día hábil.)</p>
<p>Para montos superiores a 250 Dólares, solicitados viernes despues de las 7:00 pm. sábados y domingos el pago se hará efectivo en tu cuenta los días Martes. ( Los días feriados no cuentan como día hábil.)</p>


		</div>
	<div>


	</div>
	</div>


</template>

<script>
export default {

}
</script>

<style>
.p { background-color: #FFF; }
.tabl {
	border: 1px solid #c0c0c0;
	border-collapse: collapse;
	padding: 5px;
	background: white;
	color: black;
	border-radius: 4px !important;
	width: 100%;
}
.tabd {
	border: 1px solid #c0c0c0;
	border-collapse: collapse;
	padding: 10px;
	background: white;
	color: black;
	border-radius: 4px !important;
	width: 100%;
}
.tabl th {
	border: 1px solid #c0c0c0;
	padding: 5px;
	border-radius: 4px !important;
	font-size: 12px !important;
}
.tabl td {
	border: 1px solid #c0c0c0;
	padding: 5px;
	border-radius: 4px !important;
}
</style>